
.root-box[data-v-7e54f98a] {
  background-color: #f5f5f5;
  width: 100vw;
  height: 100vh;
  padding-top: 20vh;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.line1[data-v-7e54f98a] {
  text-align: center;
}
.line1 img[data-v-7e54f98a] {
  width: 100px;
  height: 100px;
}
.line2[data-v-7e54f98a] {
  margin-top: 12px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 4px;
}
.line3[data-v-7e54f98a] {
  font-size: 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0px 50px;
  text-align: center;
  margin-top: 12px;
  color: #999;
}
.btn[data-v-7e54f98a] {
  width: 180px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 60px auto 0px auto;
  border-radius: 4px;
  background-color: #0053fb;
  color: white;
  font-size: 16px;
  letter-spacing: 4px;
}
.toBrower[data-v-7e54f98a] {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: right;
}
.toBrower img[data-v-7e54f98a] {
  width: 90vw;
}
